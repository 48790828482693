/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountService } from './services/account.service';
import { AnswerService } from './services/answer.service';
import { AuthenticationService } from './services/authentication.service';
import { CourseService } from './services/course.service';
import { DashboardService } from './services/dashboard.service';
import { FeedbacksService } from './services/feedbacks.service';
import { LessonService } from './services/lesson.service';
import { PublicDataService } from './services/public-data.service';
import { QuestionService } from './services/question.service';
import { QuestionBankService } from './services/question-bank.service';
import { QyasExamService } from './services/qyas-exam.service';
import { RequestsService } from './services/requests.service';
import { StudentService } from './services/student.service';
import { StudentTaskService } from './services/student-task.service';
import { SuperAdminActionsService } from './services/super-admin-actions.service';
import { TeacherService } from './services/teacher.service';
import { TExamService } from './services/t-exam.service';
import { UnitService } from './services/unit.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountService,
    AnswerService,
    AuthenticationService,
    CourseService,
    DashboardService,
    FeedbacksService,
    LessonService,
    PublicDataService,
    QuestionService,
    QuestionBankService,
    QyasExamService,
    RequestsService,
    StudentService,
    StudentTaskService,
    SuperAdminActionsService,
    TeacherService,
    TExamService,
    UnitService,
    ApiConfiguration
  ],
})
export class BEApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<BEApiModule> {
    return {
      ngModule: BEApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: BEApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('BEApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
