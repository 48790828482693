<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!-- ?? profile -->
  <div class="profile ">

    <a class="navbar-brand text-center" routerLink="/">
      <span *ngIf="!isCondensed" id="logo" class="d-none d-md-inline ">
        <img [src]="logos.light" class="" style="width:auto; height:50px;">
      </span>
      <span *ngIf="isCondensed" id="logo" class="d-none d-md-inline ">
        <img [src]="logos.light_sm" class="" style="width:auto; height:50px;">
      </span>
      <span id="logo" class="d-md-none "><img [src]="logos.light_sm" class="" style="width:auto; height:50px;"> </span>

    </a>
  </div>
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuItems">
        <ng-container *appRole="item.roles || []">
          <li class="menu-title" *ngIf="item.isTitle">
            <div class="d-flex align-items-center">
              <span class="flex-fill bg-light opacity-50 d-inline-flex" style="height: 1px;"></span>
              <span class="px-2">{{ item.label | translate }}</span>
              <span class="flex-fill bg-light opacity-50 d-inline-flex" style="height: 1px;"></span>
            </div>
          </li>
          <li (click)="clickElWhenCollapsed()" *ngIf="!item.isTitle && !item.isLayout">
            <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
              [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
              <!-- <span class="bx bx-chevron-right  arrow_icon  fs-5"></span> -->
              <i style="width: 20px;" class=" text-center  fs-6 {{item.icon}}" *ngIf="item.icon"></i>
              <span> {{ item.label | translate }}</span>
              <span class="badge rounded-pill float-end bg-{{item.badge.variant}}" *ngIf="item.badge">
                {{item.badge.text | translate}}</span>
            </a>

            <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref"
              [routerLinkActiveOptions]="{exact: item?.exact}" routerLinkActive="active">
              <i style="width: 20px;" class="  text-center fs-6 {{item.icon}}" *ngIf="item.icon"></i>
              <span> {{ item.label | translate }}</span>
              <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">
                {{item.badge.text | translate}}</span>
            </a>

            <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">

              <li *ngFor="let subitem of item.subItems">
                <ng-container *appRole="subitem.roles || []">
                  <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref fw-semibold"
                    [attr.data-parent]="subitem.parentId" [routerLinkActiveOptions]="{exact: subitem?.exact}"
                    routerLinkActive="active">
                    <span class="aya-chevron-left small me-1"></span>
                    {{ subitem.label | translate}}
                  </a>
                  <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                    [attr.data-parent]="subitem.parentId">
                    {{ subitem.label | translate}}
                  </a>
                  <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                    <li *ngFor="let subSubitem of subitem.subItems">
                      <ng-container *appRole="subSubitem.roles || []">
                        <a [attr.data-parent]="subSubitem.parentId"
                          [routerLinkActiveOptions]="{exact: subSubitem?.exact}" [routerLink]="subSubitem.link"
                          routerLinkActive="active" class="side-nav-link-ref">
                          {{ subSubitem.label | translate }}
                        </a>
                      </ng-container>
                    </li>
                  </ul>
                </ng-container>
              </li>
            </ul>
          </li>
        </ng-container>
      </ng-container>
      <li (click)="clickElWhenCollapsed()">
        <a href="https://wa.me/966538720990" target="_blank" class="side-nav-link-ref">
          <i style="width: 20px;" class="  text-center fs-6 isax-headphone"></i>
          <span>
            الدعم الفني
          </span>
        </a>
      </li>

    </ul>
  </div>

</ng-template>
<!-- Sidebar -->

<div style="grid-template-rows: 1fr auto;" class="vertical-menu  border-end border-light border-2">
  <ngx-simplebar class="" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
  <!-- <div class="text-center">
    <img height="30" class="mb-md-5 logo-lg mb-3 mx-auto" [src]="logos?.dark_sm" alt="">
    <img height="30" class="logo-sm mb-3 mx-auto" [src]="logos?.dark_sm" alt="">

  </div> -->
</div>
<!-- Left Sidebar End -->