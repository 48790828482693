/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TExamQuestionType } from '../../models/t-exam-question-type';
import { UpdateAnswerCommand } from '../../models/update-answer-command';
import { UpdateQuestionCommandResult } from '../../models/update-question-command-result';

export interface ApiQuestionUpdateQuestionPost$Params {
      body?: {
'AudioFile'?: Blob;
'AudioPlayTimes'?: number;
'Type'?: TExamQuestionType;
'Id'?: string;
'No'?: number;
'Title'?: string;
'LessonId'?: string;
'QuestionBankId'?: string;
'VideoLink'?: string;
'Image'?: Blob;
'QuestionImage'?: Blob;
'Answers'?: Array<UpdateAnswerCommand>;
}
}

export function apiQuestionUpdateQuestionPost(http: HttpClient, rootUrl: string, params?: ApiQuestionUpdateQuestionPost$Params, context?: HttpContext): Observable<StrictHttpResponse<UpdateQuestionCommandResult>> {
  const rb = new RequestBuilder(rootUrl, apiQuestionUpdateQuestionPost.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<UpdateQuestionCommandResult>;
    })
  );
}

apiQuestionUpdateQuestionPost.PATH = '/api/Question/UpdateQuestion';
